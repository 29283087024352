// Generated by CoffeeScript 1.10.0
(function() {
  (function($, d, w) {
    var PostLiker;
    PostLiker = (function() {
      var self;

      self = false;

      function PostLiker() {
        self = this;
      }

      PostLiker.prototype.isRunnable = function() {
        return true;
      };

      PostLiker.prototype.ready = function() {
        self = this;
        self.ajaxUrl = ajax_base_url;
      };

      PostLiker.prototype.load = function() {};

      PostLiker.prototype.clearMessages = function() {
        return w.setTimeout(function() {
          $('#modal-popup-rating').modal('hide');
          $('#global-messages').remove();
        }, 4000);
      };

      PostLiker.prototype.setMessage = function(message) {
        var msgContainer;
        msgContainer = $('<div/>', {
          id: 'global-messages'
        });
        $('<span/>', {
          text: message
        }).appendTo(msgContainer);
        $('#modal-popup-rating .modal-dialog').prepend(msgContainer);
        return $('#modal-popup-rating').modal('show');
      };

      PostLiker.prototype.likePost = function(userId, postId) {
        var dataSend;
        self.showLoading();
        dataSend = {
          'user_id': userId,
          'post_id': postId,
          'action': 'save_post_like',
          'is_ajax': 1
        };
        $.ajax({
          type: 'POST',
          url: self.ajaxUrl,
          dataType: "json",
          data: dataSend,
          success: function(data) {
            if (data.is_active) {
              $('article.post-' + postId + ' .like-action a').addClass('active');
            } else {
              $('article.post-' + postId + ' .like-action a').removeClass('active');
            }
          }
        });
      };

      PostLiker.prototype.showLoading = function() {
        $('body.single-ricetta .loading').prependTo($('.wrap.container[role="document"]'));
        return $('body.single-ricetta .loading').show();
      };

      PostLiker.prototype.hideLoading = function() {
        return $('body.single-ricetta .loading').hide();
      };

      return PostLiker;

    })();
    w.postLiker = new PostLiker();
    postLiker.ready();
  })(jQuery, document, window);

}).call(this);
